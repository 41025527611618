<template>
  <b-modal
    id="create-user-modal"
    centered
    size="lg"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
    dialog-class="my-dialog-class"
  >
    <template #modal-title> <h2 class="m-0">Create User</h2> </template>
    <validation-observer ref="createUserFormValidation">
      <b-form @submit.prevent>
        <b-form-row>
          <b-col md="6">
            <b-form-group label-for="cnic">
              <template #label>
                CNIC <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="CNIC"
                rules="required|integer|length:13"
              >
                <b-form-input
                  id="cnic"
                  v-model="cnic"
                  :state="errors.length > 0 ? false : null"
                  placeholder="CNIC"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label-for="password">
              <template #label>
                Password <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required|min:3"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    name="password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col md="6">
            <b-form-group label-for="Name">
              <template #label>
                Name <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Name"
                :rules="{ required }"
              >
                <b-form-input
                  id="Name"
                  v-model="name"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- <b-col md="6">
            <b-form-group label-for="lastName">
              <template #label>
                Last Name <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Last Name"
                :rules="{ required, regex: /^[A-Z]*$/i }"
              >
                <b-form-input
                  id="lastName"
                  v-model="lastName"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Last Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->
        </b-form-row>
        <b-form-row>
          <b-col md="6">
            <b-form-group label-for="mobile">
              <template #label>
                Mobile <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Mobile"
                rules="required|integer|length:11"
              >
                <b-form-input
                  id="mobile"
                  v-model="mobile"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Mobile"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- <b-col md="6">
            <b-form-group label-for="dateOfBirth">
              <template #label>
                Date of Birth <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Date Of Birth"
                rules="required"
              >
                <b-form-datepicker
                  id="dateOfBirth"
                  v-model="dateOfBirth"
                  :date-disabled-fn="disabledDateRange"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Date Of Birth"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->

          <!-- <b-col md="6">
            <b-form-group label-for="placeOfBirth">
              <template #label>
                Place of Birth <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Palce Of Birth"
                :rules="{ required, regex: /^[A-Z]*$/i }"
              >
                <b-form-input
                  id="placeOfBirth"
                  v-model="placeOfBirth"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Place Of Birth"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->

          <!-- <b-col md="6">
            <b-form-group label-for="motherName">
              <template #label>
                Mother Name <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Mother Name"
                rules="required"
              >
                <b-form-input
                  id="motherName"
                  v-model="motherName"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Mother Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label-for="email">
              <template #label> Email </template>
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="email"
              >
                <b-form-input
                  id="email"
                  v-model="email"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Email"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->

          <b-col md="6">
            <b-form-group label-for="role">
              <template #label>
                Role <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="role"
                rules="required"
              >
                <v-select :options="roles" v-model="role" label="name" />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-form-row>
      </b-form>
    </validation-observer>
    <template #modal-footer>
      <b-form-group class="text-right">
        <b-button variant="warning" pill @click="reset" class="mr-2">
          Reset
        </b-button>
        <b-button type="submit" variant="primary" pill @click="validationForm">
          Submit
        </b-button>
      </b-form-group>
    </template>
    <b-overlay :show="show" spinner-variant="primary" no-wrap />
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VueSelectPaginated from "@/components/ui/VueSelectPaginated.vue";
import { required } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import util from "@/util.js";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    VueSelectPaginated,
  },
  mixins: [util, togglePasswordVisibility],
  data() {
    return {
      show : false,
      required,
      password: "",
      name:"",
      cnic: "",
      mobile: "",
      role: null,
      roles: [],
    };
  },
  async mounted() {
    await this.getRolesData();
  },
  methods: {
    ...mapActions({
      createUser: "appData/creatUser",
      getRoles: "appData/getUnpaginatedUserCreateRoles",
    }),
    disabledDateRange: function (date) {
      var today = new Date();
      var newDate = new Date(date);
      return newDate > today;
    },
    async validationForm() {
      try {
        const success = await this.$refs.createUserFormValidation.validate();
        if (success) {
          await this.submit();
        }
      } catch (error) {
        this.displayError(error);
      }
    },
    async getRolesData() {
      try {
        const res = await this.getRoles();
        this.roles = res.data;
      } catch (error) {
        this.displayError(error);
      }
    },

    reset() {
      this.password = null;
      this.name = null;
      this.cnic = null;
      this.mobile = null;
      this.role = null;
    },
    async submit() {
        this.show = true;

      try {
        const res = await this.createUser({
          password: this.password,
          name: this.name,
          cnic: this.cnic,
          mobile: this.mobile,
          role: this.role.code_name,
        });

        if (res.status === 201) {
          this.$swal({
            title: "User created successfully",
            icon: "success",
          });
          this.show = false;
          this.$nextTick(() => {
            this.$bvModal.hide("create-user-modal");
          });
          this.reset();
          this.$emit("modalClosed");
        }
      } catch (error) {
        this.show = false;
        this.displayError(error);
      }
    },
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    rolesComputed() {
      const data = [];
      this.roles.map((role) => {
        data.push({ name: role.name, value: role.code_name });
      });
    },
  },
};
</script>

<style></style>
