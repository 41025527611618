<template>
  <b-modal
    id="edit-user-modal"
    centered
    size="lg"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
    dialog-class="my-dialog-class"
  >
    <template #modal-title> <h2 class="m-0">Edit User</h2> </template>
    <validation-observer ref="editUserFormValidation">
      <b-form @submit.prevent>
        <b-form-row>
          <b-col md="6">
            <b-form-group label-for="cnic">
              <template #label>
                CNIC <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="CNIC"
                rules="required|integer|length:13"
              >
                <b-form-input
                  id="cnic"
                  v-model="cnic"
                  :disabled="true"
                  :state="errors.length > 0 ? false : null"
                  placeholder="CNIC"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label-for="Name">
              <template #label>
                Name <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Name"
                :rules="{ required }"
              >
                <b-form-input
                  id="Name"
                  v-model="name"
                  :disabled="isNameDisabled"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- <b-col md="6">
            <b-form-group label-for="lastName">
              <template #label>
                Last Name <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Last Name"
                :rules="{ required, regex: /^[A-Z]*$/i }"
              >
                <b-form-input
                  id="lastName"
                  v-model="lastName"
                  :disabled="hasRole('op')"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Last Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->

          <!-- <b-col md="6">
            <b-form-group label-for="email">
              <template #label> Email </template>
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="email"
              >
                <b-form-input
                  id="email"
                  v-model="email"
                  :state="errors.length > 0 ? false : null"
                  :disabled="hasRole('op')"
                  placeholder="Email"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->
        </b-form-row>
        <b-form-row>
          <b-col md="6">
            <b-form-group label-for="mobile">
              <template #label>
                Mobile <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Mobile"
                rules="required|integer|length:11"
              >
                <b-form-input
                  id="mobile"
                  v-model="mobile"
                  :disabled="!hasRole('su')"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Mobile"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6" v-if="hasRole('su')">
            <b-form-group label-for="user_type">
              <template #label>
                User Type <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="User Type"
                rules="required"
              >
                <v-select
                  v-model="user_type"
                  :options="userTypes"
                  placeholder="User Type"
                  label="name"
                  item-text="name"
                  class="text-black v-style-chooser"
                  v-b-tooltip.hover
                  title="Select an option from the dropdown menu"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- <b-col md="6">
            <b-form-group label-for="dateOfBirth">
              <template #label>
                Date of Birth <span class="text-danger">*</span>
              </template>
              <validation-provider #default="{ errors }" name="Date Of Birth">
                <b-form-datepicker
                  id="dateOfBirth"
                  v-model="dateOfBirth"
                  :disabled="hasRole('op')"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Date Of Birth"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->

          <!-- <b-col md="6">
            <b-form-group label-for="placeOfBirth">
              <template #label>
                Place of Birth <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Palce Of Birth"
                
              >
                <b-form-input
                  id="placeOfBirth"
                  v-model="placeOfBirth"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Place Of Birth"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->

          <!-- <b-col md="6">
            <b-form-group label-for="motherName">
              <template #label>
                Mother Name <span class="text-danger">*</span>
              </template>
              <validation-provider #default="{ errors }" name="Mother Name">
                <b-form-input
                  id="motherName"
                  v-model="motherName"
                  :disabled="hasRole('op')"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Mother Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->

          <b-col md="6">
            <VueSelectPaginated
              name="Role"
              label="name"
              rules="required"
              :disabled="!hasRole('su')"
              searchBy="name"
              :prevSelected="role"
              :getListMethod="getRoles"
              @setMethod="
                (value) => {
                  role = value;
                }
              "
            />
          </b-col>
        </b-form-row>
        <b-form-row> </b-form-row>
      </b-form>
    </validation-observer>
    <template #modal-footer>
      <b-form-group class="text-right">
        <b-button type="submit" variant="info" pill @click="validationForm">
          Update
        </b-button>
      </b-form-group>
    </template>
    <b-overlay :show="show" spinner-variant="primary" no-wrap />
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VueSelectPaginated from "@/components/ui/VueSelectPaginated.vue";
import { required } from "@validations";
import util from "@/util.js";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    VueSelectPaginated,
  },
  props: {
    user: Object,
  },
  mixins: [util],
  data() {
    return {
      show: false,
      required,
      user_type:null,
      // firstName: "",
      // lastName: "",
      name: "",
      cnic: "",
      mobile: "",
      userTypes: [
        { value: 1, name: "Serving" },
        { value: 2, name: "Retired" },
        { value: 3, name: "Civilian" },
      ],
      // email: "",
      role: null,
      // dateOfBirth: null,
      // placeOfBirth : null,
      // motherName: null,
    };
  },
  async mounted() {
    if (this.user) {
      this.name = this.user.name;
      // this.lastName = this.user.last_name;
      this.cnic = this.user.cnic;
      this.mobile = this.user.mobile;
      // this.email = this.user.email;
      this.role = this.user.role_data;
      this.user_type = this.userTypes.find(type => type.value === this.user.user_type);
      // this.dateOfBirth = this.user.date_of_birth;
      // this.placeOfBirth = this.user.place_of_birth;
      // this.motherName = this.user.mother_name;
    }
  },
  methods: {
    ...mapActions({
      updateUser: "appData/updateUser",
      getRoles: "appData/getPetitionRoles",
    }),
    async validationForm() {
      try {
        const success = await this.$refs.editUserFormValidation.validate();
        if (success) {
          await this.submit();
        }
      } catch (error) {
        this.displayError(error);
      }
    },
    async submit() {
      this.show = true;

      try {
        const res = await this.updateUser({
          payload: {
            name: this.name,
            // last_name: this.lastName,
            cnic: this.cnic,
            mobile: this.mobile,
            // email: this.email,
            role: this.role.id,
            user_type: this.user_type.value,
            // date_of_birth: this.dateOfBirth,
            // place_of_birth: this.place_of_birth,
            // mother_name: this.motherName,
          },
          pk: this.user.id,
        });
        if (res.status === 200) {
          this.$swal({
            title: "User updated successfully",
            icon: "success",
          });
          this.show = false;
          this.$nextTick(() => {
            this.$bvModal.hide("edit-user-modal");
          });
          this.$emit("modalClosed");
        }
      } catch (error) {
        this.show = false;
        this.displayError(error);
      }
    },
  },
  computed: {
    ...mapGetters({
      hasRole: "appData/hasRole",
      getLoggedInUser: "appData/getUser",
    }),
    isNameDisabled() {
      return this.getLoggedInUser.role_data.code_name !== "su";
    },
  },
};
</script>

<style></style>
