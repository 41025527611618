<template>
  <div>
    <!--<div class="text-right mb-1">
            <b-button variant="primary" pill @click="createUser">
                <feather-icon icon="PlusIcon" class="mr-50" />
                <span class="align-middle">Create</span>
            </b-button>
        </div>-->
    <b-card class="text-black">
      <b-form>
        <b-row class="mb-1" align-v="center">
          <b-col md="3">
            <b-form-group label="reg_id" label-for="reg_id">
              <template #label>
                <h6 class="font-weight-bold">Registration ID</h6>
              </template>
              <b-form-input
                id="reg_id"
                v-model="reg_id"
                placeholder="Search ID"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Name" label-for="name">
              <template #label>
                <h6 class="font-weight-bold">Name</h6>
              </template>

              <b-form-input
                id="name"
                v-model="name"
                placeholder="Search name"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="CNIC" label-for="cnic">
              <template #label>
                <h6 class="font-weight-bold">CNIC</h6>
              </template>

              <b-form-input
                id="cnic"
                v-model="cnic"
                placeholder="Search CNIC"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Mobile" label-for="mobile">
              <template #label>
                <h6 class="font-weight-bold">Mobile</h6>
              </template>

              <b-form-input
                id="mobile"
                v-model="mobile"
                placeholder="Search mobile"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Created Start Date">
              <template #label>
                <h6 class="font-weight-bold">Registration Date From</h6>
              </template>
              <date-picker
                style="width: 100%"
                :disabled-date="disabledRange"
                v-model="created_at_start"
                valueType="format"
                placeholder=""
              ></date-picker>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Created End Date">
              <template #label>
                <h6 class="font-weight-bold">Registration Date To</h6>
              </template>
              <date-picker
                style="width: 100%"
                :disabled-date="disabledRange"
                v-model="created_at_end"
                valueType="format"
                placeholder=""
              ></date-picker>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <template #label>
                <h6 class="font-weight-bold">Approval Status</h6>
              </template>
              <v-select
                id="Status"
                v-model="status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                placeholder="Select Status"
                label="text"
                class="text-black"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <template #label>
                <h6 class="font-weight-bold">User Type</h6>
              </template>
              <v-select
                id="user_type"
                v-model="user_type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="user_types"
                placeholder="Select User Type"
                label="name"
                class="text-black"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <template #label>
                <h6 class="font-weight-bold">Role</h6>
              </template>
              <v-select
                id="role"
                v-model="role"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="getFilteredRoles"
                placeholder="Select Role"
                label="name"
                class="text-black"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Created Start Date">
              <template #label>
                <h6 class="font-weight-bold">Approval Date</h6>
              </template>
              <date-picker
                style="width: 100%"
                :disabled-date="disabledRange"
                v-model="approved_at"
                valueType="format"
                placeholder=""
              ></date-picker>
            </b-form-group>
          </b-col>
          <!--<b-col md="2">
                        <b-form-group>
                            <template #label>
                                <h6 class="font-weight-bold">Is Deleted</h6>
                            </template>
                            <v-select id="is_delete"
                                      v-model="is_delete"
                                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                      :options="deletes"
                                      placeholder="Select Status"
                                      label="text"
                                      class="text-black" />
                        </b-form-group>
                    </b-col>-->
          <!-- <b-col md="2">
                        <div>
                            <b-form-checkbox v-model="have_pending_reason"
                                             class="custom-control-primary">
                                Have Pending Reasons
                            </b-form-checkbox>
                        </div>
                    </b-col> -->
          <b-col md="3">
            <b-form-group>
              <template #label>
                <h6 class="font-weight-bold">Pending Reason</h6>
              </template>
              <v-select
                id="Reason"
                v-model="selectetdReason"
                :options="reasons"
                placeholder="Select reason "
                label="text"
                required
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-right mb-1">
            <b-button
              variant="secondary"
              pill
              @click="openPendingModal()"
              class="mr-2"
            >
              Pending Reason List Count
            </b-button>
            <b-button variant="info" pill @click="clearFilter()" class="mr-2">
              Clear Search
            </b-button>
            <b-button
              variant="primary"
              pill
              style="margin-right: 2%"
              @click="searchUsersData"
            >
              <feather-icon icon="SearchIcon" class="mr-50" />
              Search
            </b-button>
            <b-button variant="primary" pill @click="createUser">
              <feather-icon icon="PlusIcon" class="mr-50" />
              <span class="align-middle">Create</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <b-table
        responsive="sm"
        :fields="fields"
        sticky-header="1500px"
        :items="users"
        :paginated="true"
        details-td-class="m-0 p-0"
        small
      >
        <template #cell(is_approved)="row">
          {{
            row.item.is_approved === 1
              ? "Approved"
              : row.item.is_approved === 2
              ? "Rejected"
              : "Pending"
          }}
        </template>
        <template #cell(user_type)="row">
          {{ getUserTypeValue(row.item.user_type) }}
        </template>
        <template #cell(user_calls)="row">
          {{ row.item.user_calls }} &nbsp;
          <b-button
            style="
              background-color: #71713e !important;
              border-color: #71713e !important;
            "
            pill
            size="sm"
            @click="updateCallCount(row.item)"
          >
            Update
          </b-button>
        </template>
        <template #cell(manage)="row">
          <b-button
            variant="info"
            pill
            size="sm"
            class="mr-50"
            @click="editUser(row.item)"
            v-if="hasPermission('user_edit')"
          >
            Edit
          </b-button>
          <b-button
            variant="danger"
            pill
            size="sm"
            @click="removeUser(row.item)"
            v-if="hasPermission('user_delete') && !row.item.is_delete"
          >
            Delete
          </b-button>
          <b-button
            variant="danger"
            pill
            size="sm"
            @click="permanentDeleteUser(row.item)"
            v-if="hasPermission('permanent_user_delete') && !row.item.is_delete"
          >
            Permanent Delete
          </b-button>
          <b-button
            variant="primary"
            pill
            size="sm"
            @click="backUser(row.item)"
            v-if="hasPermission('user_delete') && row.item.is_delete"
          >
            Restore
          </b-button>
          <b-button
            variant="outline-danger"
            pill
            size="sm"
            @click="viewReasonRejected(row.item)"
            v-if="hasPermission('user_delete') && row.item.is_approved === 2"
          >
            Reject Reason
          </b-button>
          <b-button
            variant="outline-warning"
            pill
            size="sm"
            v-b-tooltip.hover.v-dark
            :title="row.item.pending_reason"
            v-if="
              row.item.pending_reason !== null &&
              row.item.pending_reason !== '' &&
              row.item.is_approved === 3
            "
          >
            Pending Reason
          </b-button>
          <!-- <b-button
                     @click="viewReasonPending(row.item)"
                        v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                        v-b-tooltip.hover.v-warning
                        title="Warning variant"
                        variant="outline-warning"
                        v-if="row.item.pending_reason !==null && row.item.pending_reason !=='' && row.item.is_approved === 3"
                    >
                        Warning
                    </b-button> -->
        </template>
      </b-table>
      <b-row>
        <b-col md="9">
          <b-pagination
            :total-rows="totalDataLength"
            :per-page="per_page"
            align="center"
            v-model="currentPage"
          />
        </b-col>
        <b-col md="2">
          <b-form>
            <b-row>
              <b-col md="6">
                <b-form-input
                  type="number"
                  v-model="page"
                  required
                ></b-form-input>
              </b-col>
              <b-col md="auto">
                <b-button  size="sm" pill @click="goTo"
                  >Go</b-button
                >
              </b-col>
            </b-row>
          </b-form>
        </b-col>
        <b-col md="1">
          <div style="float: right">
            <h5 style="margin: 0; display: inline" class="text-primary">
              Count:
            </h5>
            <h5 style="margin: 0; display: inline" align="right">
              <strong>
                {{ totalDataLength }}
              </strong>
            </h5>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <CreateUserModal @modalClosed="onModalClosed" />
    <pending-reason-count-modal
      @modalClosed="onModalClosed"
      :key="`pending-${pendingModalCount}`"
    />
    <EditUserModal
      :user="user"
      @modalClosed="onModalClosed"
      :key="`edit-${editUserModalCount}`"
    />
    <b-overlay :show="show" spinner-variant="primary" no-wrap />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CreateUserModal from "@/components/admin/CreatUserModal.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import EditUserModal from "@/components/admin/EditUserModal.vue";
import PendingReasonCountModal from "./PendingReasonCountModal.vue";
import util from "@/util.js";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { VBTooltip, BButton, BCardText } from "bootstrap-vue";
// import { codeVariantCustom } from './code'

export default {
  components: {
    DatePicker,
    ValidationProvider,
    ValidationObserver,
    required,
    CreateUserModal,
    EditUserModal,
    VBTooltip,
    BButton,
    BCardText,
    PendingReasonCountModal,
    // codeVariantCustom,
  },
  data() {
    return {
      page: 1,
      fields: [
        { key: "id", label: "Registration ID" },
        { key: "name", label: "Name" },
        { key: "cnic", label: "CNIC" },
        { key: "mobile", label: "Mobile" },
        { key: "role_data.name", label: "Role" },
        { key: "user_type", label: "User Type" },
        { key: "is_approved", label: "Approval Status" },
        { key: "user_calls", label: "call count" },
        { key: "user_email_sent", label: "Email Sent Count" },
        /* { key: "is_delete", label: "Deleted Status" },*/
        { key: "created_at", label: "Registration Date" },
        { key: "approved_at", label: "Approval Date" },
        { key: "approved_by", label: "Approved By" },
        { key: "manage", label: "Manage" },
      ],
      currentPage: 1,
      totalDataLength: 0,
      per_page: 0,
      users: [],
      user: null,
      editUserModalCount: 0,
      pendingModalCount: 0,
      cnic: null,
      mobile: null,
      statusOptions: [
        { text: "Approved", value: 1 },
        { text: "Rejected", value: 2 },
        { text: "Pending", value: 3 },
      ],
      deletes: ["True", "False"],
      is_delete: "False",
      user_types: [
        { name: "SERVING", value: 1 },
        { name: "RETIRED", value: 2 },
        { name: "CIVILIAN", value: 3 },
      ],
      user_type: null,
      status: null,
      name: null,
      role: null,
      roles: [],
      created_at_start: null,
      created_at_end: null,
      reg_id: null,
      approved_at: null,
      show: false,
      have_pending_reason: false,
      selectetdReason: null,
      reasons: [
        {
          text: "Number busy",
          value: "Number busy",
        },
        {
          text: "Not Attending",
          value: "Not Attending",
        },
        {
          text: "Number Not Responding",
          value: "Number Not Responding",
        },
        {
          text: "Phone Powered Off",
          value: "Phone Powered Off",
        },
        {
          text: "Not Interested",
          value: "Not Interested",
        },
        {
          text: "Provided wrong number during registering",
          value: "Provided wrong number during registering",
        },
        {
          text: "Data does not exist in DCR",
          value: "Data does not exist in DCR",
        },
        {
          text: "Date of birth missing in DCR",
          value: "Date of birth missing in DCR",
        },
        //{
        //  text: "Blood group does not match",
        //  value: "Blood group does not match",
        //},
        //{
        //  text: "Arms and services does not match",
        //  value: "Arms and services does not match",
        //},
        {
          text: "Rank missing in DCR",
          value: "Rank missing in DCR",
        },
        {
          text: "Service number does not match",
          value: "Service number does not match",
        },
        {
          text: "DOR missing in DCR",
          value: "DOR missing in DCR",
        },
        {
          text: "Course missing in DCR",
          value: "Course missing in DCR",
        },
        {
          text: "Parent unit missing in DCR",
          value: "Parent unit missing in DCR",
        },
        {
          text: "Last unit missing in DCR",
          value: "Last unit missing in DCR",
        },
        {
          text: "Shaheed/Deceased name does not match",
          value: "Shaheed/Deceased name does not match",
        },
        {
          text: "New User",
          value: "New User",
        },
      ],
    };
  },
  mixins: [util],
  created() {
    this.$store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", true);
  },
  destroyed() {
    this.$store.commit(
      "verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED",
      this.isVerticalMenuCollapsed
    );
  },
  async mounted() {
    try {
      await this.retreiveRolesData();
      await this.retreiveUsersData();
    } catch (error) {
      this.displayError(error);
    }
  },
  methods: {
    ...mapActions({
      deleteUser: "appData/deleteUser",
      permDeleteUser: "appData/permDeleteUser",
      updateUser: "appData/updateUser",
      createUserCall: "appData/createUserCall",
      getPaginatedUsersWithFilters: "appData/getPaginatedUsersWithFilters",
      getUnpaginatedRoles: "appData/getUnpaginatedRoles",
    }),
    async retreiveRolesData() {
      try {
        const res = await this.getUnpaginatedRoles();
        this.roles = res.data;
      } catch (error) {
        this.displayError(error);
      }
    },
    async searchUsersData() {
      try {
        this.show = true;
        //let rolesData = [];
        //if (this.role_array.length > 0) {
        //    this.role_array.map((ro) => {
        //        rolesData.push(ro.id)

        //    });
        //}
        const res = await this.getPaginatedUsersWithFilters({
          page_number: 1,
          id: this.reg_id,
          name: this.name,
          cnic: this.cnic,
          mobile: this.mobile,
          is_approved: this.status ? this.status.value : null,
          role: this.role ? this.role.id : null,
          user_type: this.user_type ? this.user_type.value : null,
          not_code_name: "su",
          is_delete: this.is_delete,
          approved_at: this.approved_at,
          // have_pending_reason: this.have_pending_reason,
          have_pending_reason: this.selectetdReason
            ? this.selectetdReason.value
            : null,
          created_at_start: this.created_at_start
            ? this.created_at_start + " " + "00:00:00"
            : null,
          created_at_end: this.created_at_end
            ? this.created_at_end + " " + "23:59:59"
            : null,
        });
        this.users = res.data.results;
        this.per_page = res.data.per_page;
        this.totalDataLength = res.data.count;
        this.totalPageCount = res.data.total_pages;
        this.show = false;
      } catch (error) {
        this.show = false;
        this.displayError(error);
      }
    },
    async goTo() {
      try {
        this.show = true;
        //let rolesData = [];
        //if (this.role_array.length > 0) {
        //    this.role_array.map((ro) => {
        //        rolesData.push(ro.id)

        //    });
        //}
        const res = await this.getPaginatedUsersWithFilters({
          page_number: this.page,
          id: this.reg_id,
          name: this.name,
          cnic: this.cnic,
          mobile: this.mobile,
          is_approved: this.status ? this.status.value : null,
          role: this.role ? this.role.id : null,
          user_type: this.user_type ? this.user_type.value : null,
          not_code_name: "su",
          is_delete: this.is_delete,
          approved_at: this.approved_at,
          // have_pending_reason: this.have_pending_reason,
          have_pending_reason: this.selectetdReason
            ? this.selectetdReason.value
            : null,
          created_at_start: this.created_at_start
            ? this.created_at_start + " " + "00:00:00"
            : null,
          created_at_end: this.created_at_end
            ? this.created_at_end + " " + "23:59:59"
            : null,
        });
        this.currentPage= this.page;
        this.users = res.data.results;
        this.per_page = res.data.per_page;
        this.totalDataLength = res.data.count;
        this.totalPageCount = res.data.total_pages;
        this.show = false;
      } catch (error) {
        this.show = false;
        this.displayError(error);
      }
    },
    async retreiveUsersData() {
      try {
        this.show = true;
        //     let rolesData = [];
        //if (this.role_array.length > 0) {
        //    this.role_array.map((ro) => {
        //        rolesData.push(ro.id)

        //    });
        //}
        const res = await this.getPaginatedUsersWithFilters({
          page_number: this.currentPage,
          id: this.reg_id,
          name: this.name,
          cnic: this.cnic,
          mobile: this.mobile,
          approved_at: this.approved_at,
          have_pending_reason: this.selectetdReason
            ? this.selectetdReason.value
            : null,
          is_approved: this.status ? this.status.value : null,
          role: this.role ? this.role.id : null,
          user_type: this.user_type ? this.user_type.value : null,
          not_code_name: "su",
          is_delete: this.is_delete,
          created_at_start: this.created_at_start
            ? this.created_at_start + " " + "00:00:00"
            : null,
          created_at_end: this.created_at_end
            ? this.created_at_end + " " + "23:59:59"
            : null,
        });
        this.users = res.data.results;
        this.per_page = res.data.per_page;
        this.totalDataLength = res.data.count;
        this.totalPageCount = res.data.total_pages;
        this.show = false;
      } catch (error) {
        this.displayError(error);
        this.show = false;
      }
    },
    async clearFilter() {
      this.reg_id = null;
      this.name = null;
      this.cnic = null;
      this.mobile = null;
      this.status = null;
      this.created_at_start = null;
      this.created_at_end = null;
      this.approved_at = null;
      this.selectetdReason = null;
      this.is_delete = "False";
      await this.retreiveUsersData();
    },
    createUser() {
      this.$nextTick(() => {
        this.$bvModal.show("create-user-modal");
      });
    },
    editUser(user) {
      this.user = user;
      this.editUserModalCount += 1;
      this.$nextTick(() => {
        this.$bvModal.show("edit-user-modal");
      });
    },
    openPendingModal() {
      this.pendingModalCount += 1;
      this.$nextTick(() => {
        this.$bvModal.show("pending-reason-count-modal");
      });
    },
    async removeUser(user) {
      this.user = user;
      this.show = true;
      try {
        this.$swal({
          title: "Are you sure to want delete this user?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, Delete!",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-primary ml-1",
          },
          buttonsStyling: false,
        }).then(async (results) => {
          if (results.value) {
            const res = await this.deleteUser({
              pk: this.user.id,
            });
            if (res.status === 200) {
              await this.retreiveUsersData();
              this.$swal({
                title: "User deleted successfully",
                icon: "success",
              });
            }
          }
        });
        this.show = false;
      } catch (error) {
        this.show = false;
        this.displayError(error);
      }
    },
    async permanentDeleteUser(user) {
      this.user = user;
      this.show = true;
      try {
        this.$swal({
          title: "Are you sure to want delete this user?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, Delete!",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-primary ml-1",
          },
          buttonsStyling: false,
        }).then(async (results) => {
          if (results.value) {
            const res = await this.permDeleteUser({
              pk: this.user.id,
            });
            if (res.status === 200) {
              await this.retreiveUsersData();
              this.$swal({
                title: "User deleted successfully",
                icon: "success",
              });
            }
          }
        });
        this.show = false;
      } catch (error) {
        this.show = false;
        this.displayError(error);
      }
    },
    async updateCallCount(user) {
      this.user = user;
      this.show = true;
      try {
        const resp = await this.createUserCall({
          called_to: user.id,
        });
        if (resp.status === 200) {
          await this.retreiveUsersData();
          this.$swal({
            title: "Call count updated successfully!",
            icon: "success",
            timer: 2500,
          });
        }
      } catch (error) {
        this.displayError(error);
      } finally {
        this.show = false;
      }
    },
    async backUser(user) {
      this.user = user;
      this.show = true;
      try {
        this.$swal({
          title: "Are you sure to want restore this user?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, Restore!",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-primary ml-1",
          },
          buttonsStyling: false,
        }).then(async (result) => {
          if (result.value) {
            const resp = await this.updateUser({
              payload: {
                is_delete: false,
              },
              pk: this.user.id,
            });
            if (resp.status === 200) {
              await this.retreiveUsersData();
              this.$swal({
                title: "User restored successfully",
                icon: "success",
              });
            }
          }
        });
        this.show = false;
      } catch (error) {
        this.show = false;
        this.displayError(error);
      }
    },
    async viewReasonPending(user) {
      this.user = user;
      try {
        this.$swal({
          title: ` ${user.pending_reason}`,
          icon: "info",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      } catch (error) {
        this.displayError(error);
      }
    },
    async viewReasonRejected(user) {
      this.user = user;
      try {
        this.$swal({
          title: ` ${user.reject_reason}`,
          icon: "info",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      } catch (error) {
        this.displayError(error);
      }
    },
    disabledRange: function (date) {
      return date < new Date(1900, 1, 1) || date > new Date();
    },
    async onModalClosed() {
      await this.retreiveUsersData();
    },
  },
  computed: {
    ...mapGetters({
      hasPermission: "appData/hasPermission",
      hasRole: "appData/hasRole",
    }),
    getFilteredRoles() {
      let rolesData = [];
      this.roles.map((ro) => {
        if (this.hasRole("op") || this.hasRole("op_admin")) {
          //Condition for Operator, operator can see only normal user in dropdown

          if (ro.code_name === "nu") {
            rolesData.push({
              name: ro.name,
              id: ro.id,
            });
          }
          if (ro.code_name == "nu") {
            this.role = {
              name: ro.name,
              id: ro.id,
            };
          }
        } else {
          //Condition for everyone else, not showing superuser role and assigning normal user in value
          if (ro.code_name !== "su") {
            rolesData.push({
              name: ro.name,
              id: ro.id,
            });
          }
          if (ro.code_name == "nu") {
            this.role = {
              name: ro.name,
              id: ro.id,
            };
          }
        }
      });
      return rolesData;
    },
  },
  watch: {
    currentPage: {
      handler: async function (value) {
        await this.retreiveUsersData();
      },
    },
  },
};
</script>

<style>
.text-black {
  color: black !important;
  /* font-size: 1.2rem; */
}
</style>
