<template>
    <div>
        <b-modal id="pending-reason-count-modal"
                 centered
                 hide-footer
                 size="sm">
            <h3 style="text-align:center" class="font-weight-bolder">
                Break Down of Pending Users
            </h3>
            <b-table striped
                     :items="users"
                     :fields="fields"
                     :tbody-tr-class="rowClass">
                <template #cell(pending_reason)="row">
                    <h6 v-if="row.item.pending_reason === 'Total'">
                        <strong>{{ row.item.pending_reason}}</strong>
                    </h6>
                    <h6 v-else>
                        {{ row.item.pending_reason}}
                    </h6>
                </template>
            </b-table>
        </b-modal>
        <div>
            <b-overlay :show="show"
                       spinner-variant="primary"
                       no-wrap />
        </div>

    </div>
</template>
<script>
    import { mapActions, } from "vuex";
    import util from "@/util.js";
    import {
        BCard, BCardBody, BCardHeader, BCardTitle, BCardSubTitle, BBadge,
    } from 'bootstrap-vue'
    export default {
        components: {
            BCard, BCardBody, BCardHeader, BCardTitle, BCardSubTitle, BBadge,
            util,
        },
        data() {
            return {
                show: false,
                fields: [
                    { key: "pending_reason", label: "Pending Reason", },
                    { key: "count", label: "Total" },
                ],
                users: [],
                userDataCount: 0,

            };
        },
        async mounted() {
            try {
                await this.retrievePendingReasonData();

            } catch (error) {
                console.log(error);
            }
        },
        methods: {
            ...mapActions({
                getPendingReasonData: "appData/getPendingReasonData",

            }),
            rowClass(item, type) {
                const colorWarning = 'table-warning'
                if (!item || type !== 'row') { return }
             
                if (item.pending_reason === "Total") { return colorWarning }
            },
            async retrievePendingReasonData() {
                try {
                    this.show = true;
                    const _res = await this.getPendingReasonData({});
                    this.users = Object.values(_res.data)[0];
                    this.show = false;
                } catch (error) {
                    this.show = false;
                    console.log(error);
                }
            },

        },

    };
</script>

<style></style>

